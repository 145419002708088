
import { onMounted, ref, computed, reactive } from "vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import OrganizationService from "@/services/OrganizationService"

export default {
  components: { LinkLabel, PrimaryButton },
  props: {
    computer: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed"],
  setup(props: any, { emit }: any) {
    const organizationService = OrganizationService.getInstance()
    const loading = ref(true)
    const items = reactive([])
    const selectedItems = ref()
    const windowWidth = ref(window.innerWidth)
    const scrollable = computed(() => windowWidth.value > 960)
    const newOrganizationDialog = ref(false)
    const deleteOrgDialog = ref(false)

    onMounted(() => {
      loading.value = false
    })

    function reload() {
      selectedItems.value = null
      items.length = 0
      emit("value-changed")
      loadOrganizations()
    }

    loadOrganizations()

    function loadOrganizations() {
      if (props.computer?.organizations?.length) {
        props.computer.organizations.forEach((org: any) => {
          let item = { selected: false, data: org }
          items.push(item)
        })
      }
    }

    function deleteSelected() {
      if (!deleteOrgDialog.value) {
        deleteOrgDialog.value = true
        return
      }
      organizationService
        .deleteComputerFromOrganization(selectedItems.value[0].data.id, props.computer.id)
        .then(() => {
          deleteOrgDialog.value = false
          reload()
        })
    }

    const form = ref({
      organization: null,
    })

    function addOrganization() {
      if (form.value.organization !== null) {
        organizationService
          .addComputerToOrganization(form.value.organization.id, props.computer.id)
          .then(() => {
            newOrganizationDialog.value = false
            reload()
          })
      }
    }

    return {
      items,
      loading,
      selectedItems,
      newOrganizationDialog,
      addOrganization,
      form,
      scrollable,
      deleteOrgDialog,
      deleteSelected,
    }
  },
}
